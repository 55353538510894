import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["customerNumberField", "salesforceErrorMessage", "orderSubmit", "companyNameField"];

  connect() {
    this.customerNumberFieldTarget.addEventListener("input", (e) => {
      if (e.target.value.length > 5) {
        this.fetchAccountInfo(e.target.value);
      }
    });

    this.fetchAccountInfo(this.customerNumberFieldTarget.value);
  }

  async fetchAccountInfo(account_number) {
    if (account_number.length < 6 || account_number === "pending") {
      this.orderSubmitTarget.disabled = true;
      return null;
    }
    const url = `/salesforce/account/${account_number}`;
    let csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    try {
      const response = await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrf,
        },
      });

      if (response.status !== 200) {
        this.salesforceErrorMessageTarget.classList.remove('hidden');
        this.orderSubmitTarget.disabled = true;
      } else {
        const data = await response.json();
        if (data.data) {
          this.salesforceErrorMessageTarget.classList.add('hidden');
          this.orderSubmitTarget.removeAttribute('disabled');

          this.companyNameFieldTarget.value = data.data["Name"];

          // Commented out because we are not using this feature yet until we understand what address options business needs
          // const address = await this.fetchAccountAddress(data.data);
          // const a1 = document.getElementById("account_address").querySelector('input[name="address_line1"]');
          // const a2 = document.getElementById("account_address").querySelector('input[name="address_line2"]');
          // const city = document.getElementById("account_address").querySelector('input[name="city"]');
          // const state = document.getElementById("account_address").querySelector('select[name="state"]');
          // const zip = document.getElementById("account_address").querySelector('input[name="zip_code"]');
          // const country = document.getElementById("account_address").querySelector('select[name="country"]');

          // a1.value = address.address_line1;
          // a2.value = address.address_line2;
          // city.value = address.city;
          // state.value = address.state;
          // zip.value = address.zip_code;
          // country.value = address.country;
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  }

  async fetchAccountAddress(address) {
    try {
      const url = `/account_address.json`;
      let csrf = document
        .querySelector("meta[name='csrf-token']")
        .getAttribute("content");

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrf,
        },
        body: JSON.stringify({
          reference_number: address.D365_Customer_Id__c,
          zip_code: address.ShippingPostalCode,
          address_line1: address.ShippingStreet,
          city: address.ShippingCity,
          state: address.ShippingState,
          country: address.ShippingCountry,
        }),
      });

      const data = await response.json();
      if (data.error) throw new Error(data.error);
      return data.address;
    } catch (e) {
      console.log("error", e);
    }
  }
}
